import React, { useEffect } from 'react';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../../Cases/Cases.scss';
import cases from '../../../data/casesInfo.json';
import '../CaseFijiv2/CaseFijiv2.scss';
import { Helmet } from 'react-helmet';

export default function CaseColgate() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="caseFiji">
      <Helmet>
        <title>How inte-QT Overcame COVID-19 Challenges to Deliver a Dedicated Internet Line in Argentina</title>
        <meta name="title" content="How inte-QT Overcame COVID-19 Challenges to Deliver a Dedicated Internet Line in Argentina" />
        <meta name="description" content="Learn how inte-QT successfully delivered a dedicated internet line in Argentina during the COVID-19 pandemic, overcoming numerous challenges to ensure customer satisfaction." />
        <link rel="canonical" href="https://www.inte-qt.com/cases/argentina" />
      </Helmet>
      <img
        src={cases[2].img}
        alt="bigbanner-background"
        className="banner-img banner-img__black"
      />
      <h1 className="banner-img__header text-center">{cases[2].title}</h1>

      <div className="container-fluid caseFiji">
        <div className="container">
          <div className="caseFiji2__width my-5">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/847uR6R.png"
                alt="technology-head"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Key Challenges Faced During Service Delivery</h2>
            </div>
            <ul>
              <li><strong>Rapid Deployment Requirements:</strong> Ensuring quick service delivery amidst strict COVID-19 regulations.</li>
              <li><strong>Stringent Health and Safety Compliance:</strong> Meeting strict health guidelines during installation.</li>
              <li><strong>High Standards for Quality of Service:</strong> Maintaining exceptional service quality in a remote location.</li>
            </ul>
          </div>

          <div className="caseFiji2__width my-5 caseFiji2__margin">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/4s6vXof.png"
                alt="wireless-signal"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Effective Solutions Implemented to Meet Customer Needs</h2>
            </div>
            <ul>
              <li>Strict adherence to COVID-19 protocols ensured safety for all parties involved.</li>
              <li>Collaboration with reliable partners guaranteed compliance with health and safety regulations.</li>
              <li>A robust network design exceeded customer expectations as part of the inte-Qt Dedicated Line service.</li>
            </ul>
          </div>

          <div className="caseFiji2__width my-5">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/55CYPwa.png"
                alt="devices-red"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Outstanding Outcomes Achieved for Our Client</h2>
            </div>
            <ul>
              <li>Customer delighted with early delivery, meeting all regulatory compliance.</li>
              <li>Exceeded quality of service parameters, enhancing overall customer satisfaction.</li>
            </ul>
          </div>

          <h2 className="my-5">Background of the Case</h2>
          <p align="justify">
            In early 2021, inte-QT faced the challenge of delivering a dedicated internet line for a Fortune 500 FMCG company in Argentina during a resurgence of COVID-19. The customer expected deployment amid strict regulations and compliance requirements.
          </p>
          <img
            src="https://i.imgur.com/mifydW7.jpg"
            alt="light-beach"
            className="caseFiji2__textimg caseFiji2__right m-3"
          />
          <p align="justify">
            At the time, the customer requested specific compliance documents related to COVID-19 health and safety for site access, which were critical for our partner’s survey and installation teams. Understanding the importance of maintaining high-quality service parameters, we prioritized compliance while ensuring robust bandwidth, low latency, and reliable service delivery.
          </p>

          <h2 className="my-5">Solution</h2>
          <p align="justify">
            Given the customer's confidence in inte-QT based on previous successful projects, we quickly engaged with them to address their urgent needs. Our strong partner network in Latin America, aligned with COVID-19 regulations, allowed us to meet the delivery timeline.
          </p>
          <p align="justify">
            Our operations team collaborated closely with both the partner and the customer to finalize the delivery framework. Effective communication was key; we assigned a dedicated resource to facilitate timely delivery through virtual meetings and discussions.
          </p>

          <img
            src="https://i.imgur.com/rMUNic4.jpg"
            className="caseFiji2__textimg caseFiji2__left m-3"
            alt="team-collaboration"
          />
          <h2 className="my-5">Outcome</h2>
          <p align="justify">
            Despite the challenges posed by the pandemic, we successfully delivered the service ahead of schedule while maintaining high-quality parameters. The network design not only met but exceeded the expectations of our client.
          </p>
          <p align="justify">
            Stringent redundancy measures were implemented to avoid any major impact on the user's operations, resulting in high customer satisfaction and delight.
          </p>

          <div className="cases__box">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h2 className="cases__companyName mx-3">{cases[1].title}</h2>
                <p className="cases__text m-3" align="justify">
                  {cases[1].p3}
                </p>
                <div className="text-end mx-3">
                  <DinamicLink url="/cases/lebanon" name="Read more" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <img src={cases[1].img} alt="Description of the case" className="cases__img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />
    </div>
  );
}
