import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import './Cases.scss';
import DinamicLink from '../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import EventsComponent from '../../components/EventsComponent/EventsComponent';
import cases from '../../data/casesInfo.json';

export default function Cases() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="cases">
      <Helmet>
        <title>Cases - inte-QT</title>
        <meta name="title" content="Cases - inte-QT" />
        <meta name="description" content="Case studies where you can see the experience we offer our customers" />
        <link rel="canonical" href="https://www.inte-qt.com/cases" />
      </Helmet>
      <img src="https://i.imgur.com/TenHkwi.jpg" alt="1" className="banner-img banner-img__black"/>
      <h1 className="banner-img__header text-center">Case Studies</h1>
      <div className="container">

        <div className="cases__box">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img src={cases[0].img} alt="1" className="cases__img"/>
            </div>
            <div className="col-lg-6 col-md-12">
              <h2 className="cases__companyName mx-3">{cases[0].title}</h2>
              <p className="cases__text m-3" align="justify">{cases[0].p3}</p>
              <div className="text-start mx-3"><DinamicLink url="/cases/fiji" name="Read more"/></div>
            </div>
          </div>
        </div>

        <div className="cases__box">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h2 className="cases__companyName mx-3">{cases[2].title}</h2>
              <p className="cases__text m-3" align="justify">{cases[2].p5}</p>
              <div className="text-end mx-3"><DinamicLink url="/cases/argentina" name="Read more"/></div>
            </div>
            <div className="col-lg-6 col-md-12">
              <img src={cases[2].img} alt="1" className="cases__img"/>
            </div>
          </div>
        </div>

      <div className="cases__box">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img src={cases[1].img} alt="1" className="cases__img"/>
            </div>
            <div className="col-lg-6 col-md-12">
              <h2 className="cases__companyName mx-3">{cases[1].title}</h2>
              <p className="cases__text m-3" align="justify">{cases[1].p3}</p>
              <div className="text-start mx-3"><DinamicLink url="/cases/lebanon" name="Read more"/></div>
            </div>
          </div>
        </div>
      </div>
      <EventsComponent />
      <GetInTouch />
    </div>
  )
}
